<template>
<v-sheet
    :color="wsLIGHTCARD"
    style="border-top-left-radius : 8px;border-top-right-radius : 8px"
    :class="[{'mt-6' : SM}]"
>

  <!--  Completed -->
  <div class="d-flex align-center  py-4"
       :class="[
           {'justify-space-between' : SM},
           {'px-6' : !SM}, {'px-3' : SM}
       ]"
  >
    <div>
      <h4  class="text-no-wrap" :style="`color : ${wsDARKER};${SM ? 'font-size: 13px' : ''}`">{{ $t('start.steps.setup_school') }}</h4>
      <h5 class="text-no-wrap" :style="`color : ${wsDARKLIGHT};${SM ? 'font-size: 11px' : ''}`">{{ $t('Completed') }} {{ completedSteps }} / {{ totalSteps }}</h5>
    </div>
    <v-sheet
        v-if="!SM"
        :color="wsBACKGROUND"
        class="d-flex align-center ml-14 mr-7"
        style="position : relative; border-radius: 5px"
        width="100%"
        height="10px"

    >
      <v-sheet
          :color="wsATTENTION"
          style="position : relative; border-radius: 5px"
          :width="completedStepsPercents"
          height="10px"
      />
    </v-sheet>
    <v-icon @click="expandGeneral = !expandGeneral" :color="wsACCENT">mdi-chevron-{{ !expandGeneral ? 'down' : 'up' }}</v-icon>
  </div>
  <v-divider :style="`border-color : ${wsBACKGROUND}`"></v-divider>

  <!--  Steps -->
  <v-expand-transition>
    <div v-if="expandGeneral">

      <!--  Base -->
      <div class="d-flex justify-space-between align-center py-4" :key="i"
           :class="[{'px-6' : !SM}, {'px-3' : SM}]"
      >
        <h4 class="text-no-wrap pointer" :style="`color : ${wsDARKER};${SM ? 'font-size: 13px' : ''}`">
          {{ $t('start.steps.base') }}
        </h4>
      </div>
      <v-divider :style="`border-color : ${wsBACKGROUND}`" :key="i + 'divider'" />
      <template v-for="(item,i) in steps" >
        <div class="d-flex justify-space-between align-center py-4" :key="i"
             :class="[{'px-6' : !SM}, {'px-3' : SM}]"
        >


          <div class="d-flex align-center">
            <v-sheet
                :color="item.completed ? wsSUCCESS :  'transparent'"
                :style="`border : 2px solid ${wsDARKLIGHT} !important`"
                class="d-flex align-center justify-center mr-4"
                style="border-radius : 50%"
                :width="!SM ? 32 : 18"
                :height="!SM ? 32 : 18"
                :min-width="!SM ? 32 : 18"
                :min-height="!SM ? 32 : 18"

            >
              <v-icon v-if="item.completed" color="#ffffff" :size="!SM ? 32 : 14">mdi-check</v-icon>
            </v-sheet>
            <h4 @click="expandAction(i)"
                class="pointer"
                :style="`color : ${wsDARKER};${SM ? 'font-size: 13px' : ''}`"
                :class="[{'text-no-wrap' : !SM}]"
                style="width: 100%"
            >
              {{ item.text }}
            </h4>
          </div>

          <v-icon @click="expandAction(i)" :color="wsACCENT">mdi-chevron-{{ expand[i] ? 'up' : 'down' }}</v-icon>
        </div>
        <v-divider :style="`border-color : ${wsBACKGROUND}`" :key="i + 'divider'" />

        <v-expand-transition :key="i + 'expanded'">
          <div v-if="expand[i]">
            <div
                class="px-6 py-4  justify-space-between"
            >
              <v-sheet
                  v-if="item.loom"
                  :color="wsATTENTION"
                  style="border-radius : 4px"

                  class="overflow-hidden mt-2"
              >
                <div style="position: relative; padding-bottom: 64.63195691202873%; height: 0;">
                  <iframe :src="`https://www.loom.com/embed/${item.loom}?sid=566c046e-6f85-4ef7-b524-2c10420ce4a2`" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe>
                </div>

              </v-sheet>

              <iframe
                  v-else-if="item.youtube"
                  width="100%" :height="!SM ? 375 : 172" :src="`https://www.youtube.com/embed/${item.youtube}?si=k5rnEeG3bAmTBuDz`" title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>



              <div>
                <h3 class="pr-4 mt-4" :style="`color : ${wsDARKER};${SM ? 'font-size: 15px' : ''}`" >{{ item.text }}</h3>
                <h4 class="font-weight-regular mt-2 pr-4"
                    :style="`color : ${wsDARKER};${SM ? 'font-size: 13px' : ''}`">
                  {{ item.description }}
                </h4>
                <h4 v-if="item.description_2" class="font-weight-regular mt-4 pr-4" :style="`color : ${wsDARKER};${SM ? 'font-size: 13px' : ''}`">{{ item.description_2 }}</h4>

                <ws-button v-if="item.button_text" :to="item.button_route || null" class="mt-4" :label="item.button_text"></ws-button>
              </div>



            </div>
            <v-divider :style="`border-color : ${wsBACKGROUND}`" :key="i + 'divider'" />
          </div>
        </v-expand-transition>

      </template>

      <!--  Elite -->

      <div class="d-flex justify-space-between align-center px-6 py-4" :key="i">
        <h4 class="text-no-wrap pointer" :style="`color : ${wsDARKER}`">
          {{ $t('start.steps.elite') }}
        </h4>
      </div>
      <v-divider :style="`border-color : ${wsBACKGROUND}`" :key="i + 'divider'" />

      <template v-for="(item,i) in stepsElite" >
        <div class="d-flex justify-space-between align-center py-4" :key="i"
             :class="[{'px-6' : !SM}, {'px-3' : SM}]"
        >


          <div class="d-flex align-center">
            <v-sheet
                :color="item.completed ? wsSUCCESS :  'transparent'"
                :style="`border : 2px solid ${wsDARKLIGHT} !important`"
                class="d-flex align-center justify-center mr-4"
                style="border-radius : 50%"
                :width="!SM ? 32 : 18"
                :height="!SM ? 32 : 18"
                :min-width="!SM ? 32 : 18"
                :min-height="!SM ? 32 : 18"

            >
              <v-icon v-if="item.completed" color="#ffffff" :size="!SM ? 32 : 14">mdi-check</v-icon>
            </v-sheet>
            <h4 @click="expandAction(i,true)"
                class="pointer"
                :style="`color : ${wsDARKER};${SM ? 'font-size: 13px' : ''}`"
                :class="[{'text-no-wrap' : !SM}]"
                style="width: 100%"
            >
              {{ item.text }}
            </h4>
          </div>

          <v-icon @click="expandAction(i,true)" :color="wsACCENT">mdi-chevron-{{ expandElite[i] ? 'up' : 'down' }}</v-icon>
        </div>
        <v-divider :style="`border-color : ${wsBACKGROUND}`" :key="i + 'divider'" />

        <v-expand-transition :key="i + 'expanded'">
          <div v-if="expandElite[i]">
            <div
                class="px-6 py-4  justify-space-between"
            >
              <v-sheet
                  v-if="item.video"
                  :color="wsATTENTION"
                  style="border-radius : 4px"

                  class="overflow-hidden mt-2"
              >
                <div style="position: relative; padding-bottom: 64.63195691202873%; height: 0;">
                  <iframe :src="`https://www.loom.com/embed/${item.video}?sid=566c046e-6f85-4ef7-b524-2c10420ce4a2`" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe>
                </div>
              </v-sheet>

              <div>
                <h3 class="pr-4 mt-4" :style="`color : ${wsDARKER};${SM ? 'font-size: 15px' : ''}`" >{{ item.text }}</h3>
                <h4 class="font-weight-regular mt-2 pr-4"
                    :style="`color : ${wsDARKER};${SM ? 'font-size: 13px' : ''}`">
                  {{ item.description }}
                </h4>
                <h4 v-if="item.description_2" class="font-weight-regular mt-4 pr-4" :style="`color : ${wsDARKER}`">{{ item.description_2 }}</h4>

                <ws-button v-if="item.button_text" :to="item.button_route || null" class="mt-4" :label="item.button_text"></ws-button>
              </div>



            </div>
            <v-divider :style="`border-color : ${wsBACKGROUND}`" :key="i + 'divider'" />
          </div>
        </v-expand-transition>

      </template>

    </div>
  </v-expand-transition>


</v-sheet>

</template>

<script>
export default {
  name: "firstSteps",
  props : {
    results : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      expand : [ true , false , false , false , false , false , false ],
      expandElite : [ false , false , false , false , false , false , false ],
      expandGeneral : true
    }
  },
  watch : {
    completedSteps() {
      if ( this.results.has_description) {
        this.expand[0] = false
        this.expand = this.COPY(this.expand)
      }
      if ( this.totalSteps === this.completedSteps) {
        this.expandGeneral = false
      }

    }
  },
  computed : {
    totalSteps() {
      return Object.keys(this.results).length
    },

    completedSteps() {
      return Object.values(this.results).filter(el => !!el).length
    },

    completedStepsPercents() {
      return (this.completedSteps/this.totalSteps*100).toFixed(0) + '%'
    },

    steps() {
      return [
        {
          text : this.$t('start.steps.description.title'),
          description : this.$t('start.steps.description.text'),
          youtube : 'rm-kYMj7fcU',
          expanded : false,
          completed : this.results.has_description,
          button_text : this.$t('ToSettings'),
          button_route : this.businessDashLink('settings/general')
        },
        {
          text : this.$t('start.steps.site.title'),
          description : this.$t('start.steps.site.text'),
          loom : 'd73effdf31c84c8086d0dfa7e480fb43',
          expanded : false,
          completed : this.results.has_site,
          button_text : this.$t('WebEditor'),
          button_route : this.businessPublicLink('editor')
        },
        {
          text : this.$t('start.steps.course.title'),
          description : this.$t('start.steps.course.text'),
          expanded : false,
          completed : this.results.has_course ,
          button_text : this.$t('CreateNewCourse'),
          button_route : this.businessDashLink('courses')
        },
        {
          text : this.$t('start.steps.payment.title'),
          description : this.$t('start.steps.payment.text'),
          expanded : false,
          completed : this.HAS_ONLINE_PAYMENT,
          button_text : this.$t('ToSettings'),
          button_route : this.businessDashLink('settings/payment')
        },
        {
          text : this.$t('start.steps.certificate.title'),
          description : this.$t('start.steps.certificate.text'),
          expanded : false,
          completed : this.results.has_certificate,
          button_text : this.$t('ToCourses'),
          button_route : this.businessDashLink('courses')
        },
        // {
        //   text : this.$t('start.steps.market.title'),
        //   description : this.$t('start.steps.market.text'),
        //   video : 'd73effdf31c84c8086d0dfa7e480fb43',
        //   expanded : false,
        //   completed : false,
        //   button_text : this.$t('ToCourses'),
        //   button_route : this.businessDashLink('courses')
        // }
      ]
    },
    stepsElite() {
      return [
        {
          text : this.$t('start.steps.chatbot.title'),
          description : this.$t('start.steps.chatbot.text'),
          expanded : false,
          completed : this.results.has_chat_bot,
          button_text : this.$t('ToChatbot'),
          button_route : this.businessDashLink('chatbots')
        },
        {
          text : this.$t('start.steps.discount.title'),
          description : this.$t('start.steps.discount.text'),
          expanded : false,
          completed : this.results.has_discount,
          button_text : this.$t('ToDiscounts'),
          button_route : this.businessDashLink('discount')
        },
        {
          text : this.$t('start.steps.referral.title'),
          description : this.$t('start.steps.referral.text'),
          expanded : false,
          completed : this.results.has_referral,
          button_text : this.$t('ToReferrals'),
          button_route : this.businessDashLink('referral')
        },
      ]
    }
  },
  methods : {
    expandAction(index , elite = false ) {
      if (!elite) {
        this.expand[index] = !this.expand[index]
        this.expand = this.COPY(this.expand)
      } else {
        this.expandElite[index] = !this.expandElite[index]
        this.expandElite = this.COPY(this.expandElite)
      }

    }
  }
}
</script>

<style scoped>

</style>