<template>
<v-sheet height="100vh" class="overflow-y-auto" >



  <!--  Pre Header -->
  <payment-plan-notification />

  <!--  Content  -->
  <v-sheet style="width : 100%; max-width : 774px" class="mx-auto " :class="[{'px-4' : !SM}]">

    <!--  Video Consultation  -->
    <div class="d-flex  pt-8">
      <div style="width : 100%"  :class="[{'px-4' : SM}]">
        <img v-if="SM" src="/library/img/start/natalya.png" style="margin-bottom: -6px" class="mr-4" width="auto" height="180" alt="">
        <v-sheet
            :style="`border : 1px solid ${wsATTENTION} !important`"
            style="border-radius : 8px"

            class="pa-4 "
        >
          <h4 :style="`${SM ? 'font-size: 13px' : ''}`">{{  $t('start.consultation.title')  }}</h4>
          <ws-button
              @click="openSupportChat('video_consult')"
              label="start.consultation.button"
              class="mt-4"
              :block="SM"
          />
        </v-sheet>
      </div>
      <img v-if="!SM" src="/library/img/start/natalya.png" class="mr-4" width="auto" height="180" alt="">







    </div>

    <!--  First steps  -->
    <first-steps
        :results="stepsResults"
    />
    <!--  Partners  -->
    <v-sheet
        :color="wsATTENTION"
        :class="[{'pa-6' : !SM},{'pa-4' : SM}, {'d-flex' : !SM}]"
        class="wsRoundedHalf justify-space-between align-center mt-8"
    >
      <div style="max-width : 600px">
        <h2 :style="`color : #ffffff`">{{ $t('start.partners.title') }}?</h2>
        <h4 :style="`color : #ffffff`" class="font-weight-regular mt-2">{{ $t('start.partners.text') }}</h4>
        <ws-button
            @click="openSupportChat('content')"
            outlined color="#ffffff" label="start.partners.button" class="mt-4"   />
      </div>


    </v-sheet>

    <!--  Actions  -->
    <h2 class="mt-8 mb-2"
        :class="[{'px-4' : SM}]"
        :style="`font-size: ${SM ? 15 : 22}px`" >
      {{ $t('start.actions.title') }}
    </h2>
    <v-sheet class="overflow-hidden mt-8"
             :class="[{'d-flex' : !SM}]"
             :color="wsLIGHTCARD" style="border-radius : 8px" >
      <v-sheet :width="!SM ? 250 : null" :min-width="!SM ? 250 : null"  :color="wsBACKGROUND" class="pa-3 d-flex align-center">
        <img
            src="/library/img/start/site_builder_inst.png"
            alt="WeStudy Login"
            height="auto"
            width="100%"
            style="mix-blend-mode: darken"
        />
      </v-sheet>
      <div :class="[{'pa-8' : !SM} , {'pa-4' : SM}]">
        <h3 :style="`color : ${wsDARKER};${SM ? 'font-size: 15px' : ''}`">
          {{ $t('start.actions.site.title') }}
        </h3>
        <h4 class="font-weight-regular mt-2" :style="`color : ${wsDARKER};${SM ? 'font-size: 13px' : ''}`">
          {{ $t('start.actions.site.text') }}
        </h4>
        <ws-button
            :to="businessDashLink('plans_payments')"
            label="start.actions.site.button"
            class="mt-4 mr-3"
            :block="SM"
        />
        <a href="https://westudy.ua/news/spilnota" target="_blank" class="noUnderline">
          <ws-button
              label="AboutAction"
              :class="[{'mt-4' : !SM} , {'mt-2' : SM}]"
              :block="SM"
              outlined
          />
        </a>

      </div>
    </v-sheet>

    <!--  Socials  -->
    <h3 class="mt-8 mb-4" :class="[{'px-4' : SM}]" :style="`font-size: ${SM ? 15 : 22}px`" >
      {{ $t('start.subscribe.title') }}
    </h3>

    <v-row :no-gutters="SM">
      <v-col cols="12" md="6" :class="[{'px-4' : SM}]">
        <v-hover #default="{hover}">
          <a href="https://t.me/westudy_ua" class="noUnderline" target="_blank">
            <v-sheet :color="!hover ? wsLIGHTCARD : wsATTENTION" class="pa-6 d-flex align-center" style="border-radius : 8px">
              <ws-icon icon="telegram" :color="wsATTENTION" :dark="hover" size="32" />
              <h4 :style="`color : ${!hover ? wsACCENT : '#ffffff'}`">
                {{ $t('start.subscribe.telegram') }}
              </h4>
            </v-sheet>
          </a>
        </v-hover>
      </v-col>

      <v-col cols="12" md="6" :class="[{'px-4' : SM} , {'mt-4' : SM}]">
        <v-hover #default="{hover}">
          <a href="https://www.instagram.com/westudy_ua" class="noUnderline" target="_blank">
            <v-sheet :color="!hover ? wsLIGHTCARD : wsATTENTION" class="pa-6 d-flex align-center" style="border-radius : 8px">
              <ws-icon icon="instagram" :color="wsATTENTION" :dark="hover" size="32" />
              <h4 :style="`color : ${!hover ? wsACCENT : '#ffffff'}`">
                {{ $t('start.subscribe.instagram') }}
              </h4>
            </v-sheet>
          </a>
        </v-hover>
      </v-col>

      <v-col cols="12" md="6" :class="[{'px-4' : SM} , {'mt-4' : SM}]" >
        <v-hover #default="{hover}">
          <a href="https://www.facebook.com/westudy.ua" class="noUnderline" target="_blank">
            <v-sheet :color="!hover ? wsLIGHTCARD : wsATTENTION" class="pa-6 d-flex align-center" style="border-radius : 8px">
              <ws-icon icon="facebook" :color="wsATTENTION" :dark="hover" size="32" />
              <h4 :style="`color : ${!hover ? wsACCENT : '#ffffff'}`">
                {{ $t('start.subscribe.facebook') }}
              </h4>
            </v-sheet>
          </a>
        </v-hover>
      </v-col>

      <v-col cols="12" md="6" :class="[{'px-4' : SM} , {'mt-4' : SM}]" >
        <v-hover #default="{hover}">
          <a href="https://www.youtube.com/@westudy_ua" class="noUnderline" target="_blank">
            <v-sheet :color="!hover ? wsLIGHTCARD : wsATTENTION" class="pa-6 d-flex align-center" style="border-radius : 8px">
              <ws-icon icon="youtube" :color="wsATTENTION" :dark="hover" size="32" />
              <h4 :style="`color : ${!hover ? wsACCENT : '#ffffff'}`">
                {{ $t('start.subscribe.youtube') }}
              </h4>
            </v-sheet>
          </a>
        </v-hover>
      </v-col>



    </v-row>

    <!--  News  -->
    <h3 class="mt-8 mb-8" :class="[{'px-4' : SM}]" :style="`font-size: ${SM ? 15 : 22}px`">
      {{ $t('start.news.title') }}
    </h3>

    <div v-for="(item,i) in news" :key="i + 'news'" :class="[{'px-4' : SM}]">
      <div v-if="i < 3">
        <ws-link
            :to="!item.external_link && !!item.alias && item.has_content ? localeLink('news/' + item.alias) : null"
            :href="item.external_link || null "
            new-window
        >
          <h4 :style="`color : ${wsDARKER};${SM ? 'font-size: 13px' : ''}`" class="linkHover">{{ item.title }} </h4>
        </ws-link>
        <h5 :style="`color : ${wsDARKLIGHT};${SM ? 'font-size: 11px' : ''}`" class="font-weight-regular">{{ MONTH_DAY_TIME(item.date_published,false)  }}</h5>
        <v-divider class="my-4" :style="`border-color : ${wsBACKGROUND}`" />
      </div>
    </div>

    <div :class="[{'px-4' : SM}]">
      <ws-button
          label="AllNews"
          :to="localeLink('news')"
          :block="SM"
          new-window
      />
    </div>


    <v-sheet color="transparent" height="150"></v-sheet>


  </v-sheet>

</v-sheet>
</template>

<script>
import firstSteps from "@/components/pages/businessDashboard/businessGetStarted/UI/firstSteps";
import {mapActions} from "vuex";
import paymentPlanNotification from "@/components/pages/businessDashboard/UI/paymentPlanNotification";
export default {
  name: "BusinessGetStarted",
  components : {
    firstSteps,
    paymentPlanNotification
  },
  data() {
    return {
      entityData : {},
      displayMeetingDialog : false,
      news : [],
      stepsResults : {}
    }
  },
  methods : {
    ...mapActions('support', [
          'GET_STARTED',
        ]
    ),

    ...mapActions('news', [
          'GET_NEWS_MAIN_PUBLIC'
        ]
    ),
    openSupportChat(chatType) {
      this.$store.state.support.triggerSupportButton++
      setTimeout(()=> {
        this.$store.state.support.triggerSupportChat++
        setTimeout(()=> {
          this.$store.state.support.triggerSupportChatType = chatType
        } , 300)
      },300)
    },
    async getNews(loadMore = false) {
      if ( !loadMore ) {
        this.news = []
      }
      let data = {
        category_id : this.selectedCategory,
        offset : this.news.length
      }

      let result = await this.GET_NEWS_MAIN_PUBLIC(data)
      if ( !result ) {
        return
      }
      if ( !loadMore ) {
        this.news = result.news
      } else {
        if ( result.news.length > 0 )
          result.news.forEach(el => {
            this.news.push(el)
          })
      }
      this.isLast = result.is_last || false

    },

    openMeetingDialog() {
      this.entityData = {
        type : 'video_call'
      }
      this.displayMeetingDialog = true
    },
    async initPage() {
      let result = await this.GET_STARTED()
      if ( !result ) {
        return this.ERROR()
      }
      this.stepsResults = result
    }
  },
  mounted() {
    this.getNews()
    this.initPage()
  }
}
</script>

<style scoped>

</style>